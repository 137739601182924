import React from 'react';
import cn from 'classnames';
import useRouter from '@/Framework/hooks/useNextRouter';
import Favicon from './Favicon';
import Header from '@/dataroom/ui/components/Landing/Legacy/Sections/Header';
import CookiesBanner from '@/Framework/UI/Molecules/CookiesBanner';
import Footer from './Footer';
import BookDemoButton from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoModalLegacy/BookDemoButton';
import config from '@/dataroom/application/config/config';
import TenantContext from '@/Framework/Tenant/TenantContext';
import ScrollBodyBackground from '@/Framework/UI/Templates/ScrollBodyBackground';
import stickyFooterStyles from '@/Framework/UI/Organisms/StickyFooter/stickyFooter.scss';
import headerStyles from '@/dataroom/ui/components/Landing/Legacy/Sections/Header/header.scss';
import styles from './tenantVeriSend.scss';
import logo from '@/dataroom/ui/assets/veriSendLogoText.svg';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';

interface IProps {
  children: React.ReactNode,
}

const TenantVeriSend = ({ children }: IProps) => {
  const tenant = config.tenant.tenantVeriSend.code;
  const { asPath: pathname } = useRouter();

  const loginUrl = dataroomUrl(tenant).getLoginUrl(
    dmPortalUrl.getUrl(`/${ tenant }`),
  );

  const registerUrl = dataroomUrl(tenant).getRegisterUrl(
    dataroomUrl(tenant).getUrl(pathname),
  );

  return (
    <TenantContext tenant={ tenant }>
      <Favicon />
      <Header
        tenant={ tenant }
        logo={ logo }
        menu={ [] }
        button={ () => (
          <BookDemoButton className={ headerStyles.menuBtn } />
        ) }
        loginUrl={ loginUrl }
        registerUrl={ registerUrl }
        isNext
      />
      <div className={ cn(styles.pageContainer, stickyFooterStyles.pageContainerWithStickyFooter) }>
        <ScrollBodyBackground tenant={ tenant } />
        { children }
      </div>
      <Footer tenant={ tenant } />
      <CookiesBanner />
    </TenantContext>
  );
};

export default TenantVeriSend;
